import React from "react"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"

const NotFoundPage = () => (
  <div>
    <Header />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <Footer />
  </div>
)

export default NotFoundPage
